<template>
    <el-form :model="form.data" ref="solutionForm">
        <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.question.solution.cols.content') }}</label>
                            <el-form-item prop="content">
                                <CustomCkeditor :model="form.data.content" @updateModel="form.data.content = $event"></CustomCkeditor>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.question.solution.cols.streamProviderData.api') }}</label>
                            <el-form-item prop="stream_provider_data.api">
                                <el-select v-model="form.data.stream_provider_data.api" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                                    <el-option v-for="(api, apiIndex) in apiList" :key="apiIndex" :value="api" :label="api"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.question.solution.cols.streamProviderData.videoID') }}</label>
                            <el-form-item prop="stream_provider_data.video_id">
                                <el-input v-model="form.data.stream_provider_data.video_id" type="text"/>
                            </el-form-item>
                        </div>

                        <div class="fv-row mt-5">
                            <el-form-item class="mb-0">
                                <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                    <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                    <span v-if="form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import CustomCkeditor from "@/components/custom-ckeditor";

export default {
    name: "index",
    props: ['questionID'],
    components: {
        CustomCkeditor
    },
    data(){
        return {
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {
                    stream_provider_data: {}
                }
            },
            question: {},
            apiList: ['VIMEO'], //'VISTREAM'
        }
    },
    mounted(){
        this.loadQuestion();
    },
    methods: {
        loadQuestion(){
            this.axios.get(this.endpoints['module_exam_question'] + '/' + this.questionID).then((response) => {
                let data = response.data.data;
                this.question = data;
                this.form.data = this.resolveDatum(data.solutions, this.$root.defaultLanguage.id, 'language_id',
                    {
                        language_id: this.$root.defaultLanguage.id,
                        question_id: this.questionID
                    }
                );

                if(!this.form.data.stream_provider_data) {
                    this.form.data.stream_provider_data = {};
                }
            })
        },
        onSubmit() {
            this.$refs.solutionForm.validate((valid) => {
                if (valid) {
                    if(!this.checkSolutionData()){
                        return;
                    }

                    this.form.loading = true;

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['module_exam_question_solution'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadQuestion();
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            if (error.response.data.message == 'video_id_invalid') {
                                error.response.data.message = this.$t('pages.module.examManagement.question.solution.responseMessages.videoIdInvalid');
                            }

                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoints['module_exam_question_solution'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadQuestion();
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            if (error.response.data.message == 'video_id_invalid') {
                                error.response.data.message = this.$t('pages.module.examManagement.question.solution.responseMessages.videoIdInvalid');
                            }

                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        checkSolutionData(){
            try {
                let formData = this.form.data;

                if(!((formData.content && formData.content.trim().length) ||
                    (formData.stream_provider_data.api && formData.stream_provider_data.video_id && formData.stream_provider_data.video_id.trim().length))){
                    throw 'required_content_or_stream_provider';
                }

                return true;
            } catch (err){
                this.$notify({
                    type: 'warning',
                    title: this.$t("messages.warning"),
                    message: this.$t("pages.module.examManagement.question.solution.warnings." + err),
                })
                return false;
            }
        }
    }
}
</script>

<style scoped>

</style>