<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ $t('pages.module.examManagement.question.save.' + (questionID ? 'editQuestion' : 'newQuestion')) }}
        </h2>

        <router-link to="/module/exam-management/question" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.examManagement.question.title") }}
        </router-link>
    </div>

    <el-tabs v-model="tabModel" class="question-tabs">
        <el-tab-pane :label="$t('pages.module.examManagement.question.save.tabs.generalDetails')" name="general">
            <el-form :model="form" ref="questionForm">
                <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.question.cols.content') }}</label>
                                    <el-form-item prop="translate.content" :rules="$validation.getMessage(['required'])">
                                        <CustomCkeditor :model="form.translate.content" @updateModel="form.translate.content = $event"></CustomCkeditor>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="card mt-7" v-if="selectedQuestionType.code == 'NORMAL'">
                            <div class="card-header">
                                <h2 class="card-title fw-bolder">{{ $t('pages.module.examManagement.question.answer.title') }}</h2>
                                <div class="card-toolbar">
                                    <button class="btn btn-flex btn-primary" v-on:click="newAnswer" type="button">
                                        <span class="svg-icon svg-icon-2">
                                            <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                                        </span>
                                        {{ $t('pages.module.examManagement.question.answer.newAnswer') }}
                                    </button>
                                </div>
                            </div>
                            <div class="card-body d-flex flex-column p-9" v-if="form.answers.length">
                               <div class="row">
                                   <div class="col-md-6" v-for="(answer, answerIndex) in form.answers" :key="answerIndex" :class="[(answerIndex > 1 ? 'mt-md-4' : 'mt-md-0'), answerIndex != 0 && 'mt-4' ]">
                                       <div class="mb-2 d-flex justify-content-between align-items-center">
                                           <div class="text-gray-800 fs-4 fw-bolder">
                                               {{ sprintf($t('pages.module.examManagement.question.answer.titlePattern'), [$root.questionLetters[answerIndex]])}}
                                           </div>

                                           <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteAnswer(answer, answerIndex)">
                                               <template #reference>
                                                   <el-button class="removeAnswerButton" type="danger"><i class="bi bi-trash"/></el-button>
                                               </template>
                                           </el-popconfirm>

                                        </div>
                                        <div class="border border-gray-300 pt-5 px-3 pb-0 rounded">
                                            <div class="fv-row mb-1">
                                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.question.answer.cols.content') }}</label>
                                                <el-form-item :prop="'answers['+answerIndex+'].translate.content'" :rules="$validation.getMessage(['required'])">
                                                    <CustomCkeditor :model="form.answers[answerIndex].translate.content" @updateModel="form.answers[answerIndex].translate.content = $event"></CustomCkeditor>
                                                </el-form-item>
                                            </div>
                                            <div class="fv-row mb-1">
                                                <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                                                <el-form-item :prop="'answers['+answerIndex+'].data.sort'" :rules="$validation.getMessage(['required'])">
                                                    <el-input-number v-model="form.answers[answerIndex].data.sort" :min="1"/>
                                                </el-form-item>
                                            </div>
                                        </div>
                                   </div>
                               </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.question.save.cols.category') }}</label>
                                    <el-form-item prop="data.category_id" :rules="$validation.getMessage(['required'])">
                                        <el-input type="hidden" v-model="form.data.category_id"></el-input>
                                        <el-select-tree :value="form.data.category_id" @input="form.data.category_id = $event" :data="treeCategories" :multiple="false" :placeholder="$t('common.chooseSelect')"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.question.save.cols.type') }}</label>
                                    <el-form-item prop="data.type_id" :rules="$validation.getMessage(['required'])">
                                        <el-select v-model="form.data.type_id" class="w-100" :placeholder="$t('common.chooseSelect')" :disabled="form.updateStatus" @change="form.data.correct_answer_id = undefined;">
                                            <el-option v-for="(type, typeIndex) in types" :key="typeIndex" :value="type.id" :label="type.name"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1" v-if="selectedQuestionType.code == 'IMAGE'">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.question.save.cols.externalAnswerCount') }}</label>
                                    <el-form-item prop="data.external_answer_count" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.external_answer_count" :min="1" :max="5" @change="form.data.correct_answer_id = undefined"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1" v-if="(['IMAGE', 'TRUE_OR_FALSE'].includes(selectedQuestionType.code) || (form.updateStatus && selectedQuestionType.code == 'NORMAL')) && validatedAnswers.length">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.question.cols.correctAnswer') }}</label>
                                    <el-form-item prop="data.correct_answer_id" :rules="$validation.getMessage(['required'])">
                                        <el-select v-model="form.data.correct_answer_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                            <el-option v-for="(answer, answerIndex) in validatedAnswers" :key="answer.value" :value="answer.value" :label="answer.label"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.question.save.cols.gain') }}</label>
                                    <el-form-item prop="data.gains">
                                        <el-select v-model="form.data.gains" class="w-100" :placeholder="$t('common.chooseSelect')" multiple clearable>
                                            <el-option v-for="(gain, gainIndex) in gains" :key="gainIndex" :value="gain.id" :label="gain.translate.title"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mt-5">
                                    <el-form-item class="mb-0">
                                        <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                            <span v-if="form.loading" class="indicator-progress">
                                                {{ $t("messages.wait") }}
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.module.examManagement.question.save.tabs.solutionSettings')" name="solutionSettings">
            <ExamQuestionSolution :questionID="questionID" v-if="tabModel == 'solutionSettings'"></ExamQuestionSolution>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import ElSelectTree from "@/components/ElSelectTree.vue";
import CustomCkeditor from "@/components/custom-ckeditor";
import ExamQuestionSolution from "@/components/module/exam/question/solution";

export default {
    name: "_id",
    components: {
        ElSelectTree,
        CustomCkeditor,
        ExamQuestionSolution
    },
    data() {
        return {
            tabModel: 'general',
            form: {
                updateStatus: false,
                loading: false,
                translate: {
                    language_id: this.$root.defaultLanguage.id
                },
                data: {
                    type_id: 1,
                    gains: [],
                    external_answer_count: 5
                },
                answers: []
            },
            question: {
                translate: {}
            },
            treeCategories: [],
        }
    },
    computed: {
        questionID() {
            return this.$route.params.id;
        },
        types() {
            return this.$store.state.module.exam.question.type.table.data;
        },
        gains() {
            return this.$store.state.module.exam.gain.table.data.map((gain) => {
                gain.translate =  this.resolveDatum(gain.translations, this.$root.defaultLanguage.id, 'language_id');

                return gain;
            });
        },
        validatedAnswers(){
            let answers = [];

            if(this.selectedQuestionType.code == 'NORMAL'){
                this.form.answers.forEach((answer, answerIndex) => {
                    if(answer.data.id){
                        answers.push({
                            label: this.sprintf(
                                this.$t('pages.module.examManagement.question.answer.titlePattern'),
                                [this.$root.questionLetters[answerIndex]]
                            ),
                            value: answer.data.id
                        });
                    }
                })
            }

            if(this.selectedQuestionType.code == 'IMAGE'){
                for(let i = 0; i < this.form.data.external_answer_count; i++) {
                    answers.push({
                        label: this.sprintf(
                            this.$t('pages.module.examManagement.question.answer.titlePattern'),
                            [this.$root.questionLetters[i]]
                        ),
                        value: (i + 1)
                    });
                }
            }

            if(this.selectedQuestionType.code == 'TRUE_OR_FALSE'){
                answers = [
                    { label: this.$t('common.true'), value: 1},
                    { label: this.$t('common.false'), value: 2}
                ]
            }

            return answers;
        },
        selectedQuestionType(){
            let selectQuestionType = {};

            if(this.form.data.type_id){
                let found = this.types.filter((type) => type.id == this.form.data.type_id);
                if(found.length == 1){
                    selectQuestionType = found[0];
                }
            }

            return selectQuestionType;
        }
    },
    created() {
        if (this.questionID && !(this.questionID > 0)) {
            this.$router.push({
                path: "/module/exam-management/question"
            });
        }

        let answers = [];
        for(let i = 1; i <= 5; i++){
            answers.push({ translate: {}, data: {sort: i}})
        }

        this.form.answers = answers;
    },
    mounted() {
        if (this.questionID && this.questionID > 0) {
            this.loadQuestion();
        }

        this.loadTreeCategories();

        this.$store.dispatch('module/exam/gain/get', {
            page: { pageSize: 9999 }
        });

        this.$store.dispatch('module/exam/question/type/get', {
            page: { pageSize: 9999 }
        });
    },
    methods: {
        loadQuestion(questionID = this.questionID) {
            this.axios.get(this.endpoints['module_exam_question'] + '/' + questionID).then((response) => {
                let data = response.data.data;

                data.translate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id');
                data.gains = data.gains.map((gain) => {
                    return gain.id
                });

                this.question = data;
                this.form.translate = data.translate;

                this.form.updateStatus = true;
                this.form.data = data;

                let answers = [];
                data.answers.forEach((answer) => {
                    answers.push({
                        translate: this.resolveDatum(answer.translations, this.$root.defaultLanguage.id, 'language_id'),
                        data: answer,
                    })
                });

                if(answers.length) {
                    this.form.answers = answers;
                }
            })
        },
        loadTreeCategories() {
            this.axios.get(this.endpoints['module_exam_question_category'] + '/tree').then((response) => {
                this.treeCategories = response.data.data;
            });
        },
        onSubmit() {
            this.$refs.questionForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;
                    let formData = this.prepareFormData();

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['module_exam_question'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                if(this.selectedQuestionType.code == 'NORMAL' && this.form.answers.length){
                                    this.onSubmitAnswer().then((response) => {
                                        if(response.status){
                                            this.loadQuestion();
                                        }
                                    })
                                } else {
                                    this.loadQuestion();
                                }
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoints['module_exam_question'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                let questionID = response.data.data.id;
                                this.$router.push({
                                    path: "/module/exam-management/question/save/" + questionID
                                });

                                if(this.selectedQuestionType.code == 'NORMAL' && this.form.answers.length){
                                    this.onSubmitAnswer(questionID).then((response) => {
                                        if(response.status){
                                            this.loadQuestion(questionID);
                                        }
                                    })
                                } else {
                                    this.loadQuestion(questionID);
                                }
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        prepareFormData() {
            let formData = this.form.data;

            return {...this.form.translate, ...formData}
        },
        async onSubmitAnswer(questionID = this.questionID){
            return new Promise((resolve, reject) => {
                this.form.loading = true;
                let answerRequest = [];

                this.form.answers.forEach((answer) => {
                    answer.data.question_id = questionID;
                    answer.translate.language_id = this.form.translate.language_id;

                    let formData = this.prepareFormAnswerData(answer);

                    if (answer.data.id) {
                        answerRequest.push(this.axios.put(this.endpoints['module_exam_question_answer'] + '/' + answer.data.id, formData));
                    } else {
                        answerRequest.push(this.axios.post(this.endpoints['module_exam_question_answer'], formData));
                    }
                })

                this.axios.all(answerRequest).then(this.axios.spread((...responses) => {
                    let result = [];

                    for (let i = 0; i < responses.length; i++) {
                        let handleResponse = responses[i].data;

                        if (!handleResponse.status) {
                            break;
                        }

                        result.push(handleResponse.data);
                    }

                    if (result.length == responses.length) {
                        resolve({status: true, data: result});
                    } else {
                        reject('request_count_dont_match_with_response_count');
                    }
                })).catch((errors) => {
                    reject('occurred_any_error');
                })
            });
        },
        prepareFormAnswerData(answer) {
            let data = this.cloneData(answer.data);

            return {...answer.translate, ...data}
        },
        newAnswer(){
            let answerLength = this.form.answers.length;

            this.form.answers.push({
                translate: {},
                data: {
                    sort: answerLength ? (this.form.answers[answerLength - 1].data.sort + 1) : 1
                }
            })
        },
        deleteAnswer(answer, answerIndex){
            if(answer.data.id) {
                this.$store.dispatch("module/exam/question/answer/delete", {
                    id: [answer.data.id]
                }).then((successDeleted) => {
                    if(successDeleted){
                        this.loadQuestion();
                    }
                });
            } else {
                this.form.answers.splice(answerIndex, 1);
            }
        },
    }
}
</script>

<style>
.removeAnswerButton {
    padding: 8px 15px;
}
.removeAnswerButton .bi{
    font-size: 1.2rem;
}
</style>